<template>
  <v-defaults-provider
    :defaults="{
      VApp: { style: 'font-family: Poppins, sans-serif;' },
      VHeading: { style: 'font-family: Poppins, sans-serif;' },
      VBtn: { style: 'font-family: Poppins, sans-serif;' },
    }"
  >
    <LoadingBackdrop />
    <Message />
    <ConfirmDialog />
    <router-view />
    <DialogAskPresenceToPractitioner
      v-model:dialog="dialogAskPresenceToPractitioner"
    />
    <DialogAvisConsultation v-model:dialogAvis="dialogAvisConsultation" />
    <DialogYouWereAbsent v-model:dialog="dialogYouWereAbsent" />
    <AppointmentCanceledDialog v-model="dialogAppointmentCanceled" />
    <VersionChip />
  </v-defaults-provider>
</template>

<script lang="ts" setup>
import Message from '@/components/feedback/message/Message.vue'
import LoadingBackdrop from '@/components/feedback/loadingBackdrop/LoadingBackdrop.vue'
import ConfirmDialog from '@/components/feedback/confirmDialog/ConfirmDialog.vue'
import DialogAskPresenceToPractitioner from '@/components/dialog/DialogAskPresenceToPractitioner.vue'
import DialogAvisConsultation from '@/components/dialog/DialogAvisConsultation.vue'
import DialogYouWereAbsent from '@/components/dialog/DialogYouWereAbsent.vue'
import { onMounted, ref } from 'vue'
import EventBus from './utils/eventBus'
import AppointmentCanceledDialog from '@/components/feedback/absenceService/AppointmentCanceledDialog.vue'
import router from './router'
import { useAbsenceServiceStore } from './store/absenceServiceStore/absenceServiceStore'
import VersionChip from './components/VersionChip.vue'
import { useUserStore } from './store/user/userStore'
import { useAskPatientPresenceToPractitionerStore } from './store/askPatientPresenceToPractitioner/askPatientPresenceToPractitionerStore'
import { useDialogAvisConsultationStore } from './store/dialogAvisConsultationStore/dialogAvisConsultationStore'
import { useDialogYourAbsenceStore } from './store/dialogYourAbsenceStore/dialogYourAbsenceStore'

const absenceServiceStore = useAbsenceServiceStore()
const userStore = useUserStore()

onMounted(async () => {
  if (userStore.isLogged) {
    await startLoopsOnDialogReviews()
  }
})

const startLoopsOnDialogReviews = async () => {
  if (userStore.user.profilType === 'practitioner') {
    // Store to ask if patient were present to practitioner
    const dialogAskPractitionerConsultationStore =
      useAskPatientPresenceToPractitionerStore()
    await dialogAskPractitionerConsultationStore.startLoop()
  }

  if (
    userStore.user.profilType === 'patient' ||
    userStore.user.profilType === 'practitioner'
  ) {
    // Store to ask reviews to patient
    const dialogAvisConsultationStore = useDialogAvisConsultationStore()
    await dialogAvisConsultationStore.startLoop()

    // Ask to confirm the absence to the patient
    const dialogYourAbsenceStore = useDialogYourAbsenceStore()
    await dialogYourAbsenceStore.startLoop()
  }
}

/** ask presence of patient to practitioner logic */
const dialogAskPresenceToPractitioner = ref(false)
function openAskPresenceDialog() {
  dialogAskPresenceToPractitioner.value = true
}
function closeAskPresenceDialog() {
  dialogAskPresenceToPractitioner.value = false
}
EventBus.on('open-practitioner-ask-presence-dialog', openAskPresenceDialog)
EventBus.on('close-practitioner-ask-presence-dialog', closeAskPresenceDialog)
/** end ask presence of patient to practitioner logic */

/** ask review to patient logic */
const dialogAvisConsultation = ref(false)
function openDialogAvisConsultation() {
  dialogAvisConsultation.value = true
}
function closeDialogAvisConsultation() {
  dialogAvisConsultation.value = false
}
EventBus.on('open-dialog-avis-consultation', openDialogAvisConsultation)
EventBus.on('close-dialog-avis-consultation', closeDialogAvisConsultation)
/** end ask review to patient logic */

/** ask to patient that he wasn't present logic */
const dialogYouWereAbsent = ref(false)
function openDialogYouWereAbsent() {
  dialogYouWereAbsent.value = true
}
function closeDialogYouWereAbsent() {
  dialogYouWereAbsent.value = false
}
EventBus.on('open-dialog-your-absence', openDialogYouWereAbsent)
EventBus.on('close-dialog-your-absence', closeDialogYouWereAbsent)
/** end ask to patient that he wasn't present logic */

/** dialog temporary appointment canceled logic */
const dialogAppointmentCanceled = ref(false)
const openCanceledAppointmentDialog = async () => {
  EventBus.emit('close-confirm')
  await router.push({
    name: 'PractitionerPublicProfile',
    params: {
      ...absenceServiceStore.publicProfileParams,
    },
  })
  dialogAppointmentCanceled.value = true
}
EventBus.on('tmp-appointment-canceled', openCanceledAppointmentDialog)
/** end dialog temporary appointment canceled logic */
</script>

<!-- Global style -->
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&family=Roboto:wght@100;300;400;500;700;900&display=swap');

:root {
  --v-shadow-key-penumbra-opacity: rgba(0, 0, 0, 0.14);
  --v-shadow-key-umbra-opacity: rgba(0, 0, 0, 0.05);
}
a {
  color: rgba(var(--v-theme-primary));
}

.flex-b-fit {
  flex-basis: fit-content;
}

$body-font-family: 'Roboto', sans-serif;
$title-font-family: 'Poppins', sans-serif;

body,
section {
  font-family: $body-font-family;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $title-font-family;
}
.sx__current-time-indicator {
  z-index: 12 !important;
}
.sx__week-header {
  z-index: 13 !important;
}
.v-field__outline__notch {
  max-width: 60% !important;
}
@media screen and (max-width: 1080px) {
  .v-field__clearable {
    opacity: 1 !important;
  }
}
.v-alert {
  max-width: calc(100% - 32px) !important;
}

.flex-wrap {
  flex-wrap: wrap;
  gap: 12px;
}
@media screen and (max-width: 540px) {
  .flex-wrap {
    flex-direction: column-reverse;
    align-items: center;
  }
}
</style>
