<template>
  <v-dialog
    :model-value="dialog"
    @update:model-value="updateDialog"
    persistent
    max-width="600"
    transition="dialog-top-transition"
    class="blur-background"
  >
    <v-card elevation="24" class="border-radius-none">
      <v-card-title
        class="d-flex pa-4 justify-center dialog-header align-center text-center"
      >
        <span>{{ $t('dashboard.consultationDialog.title') }}</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div class="h-overflow">
          <v-list>
            <v-list-item
              v-for="consultation in dialogAskPractitionerConsultationStore.consultations"
              :key="consultation.id"
              class="mb-4 border-list"
            >
              <v-card class="pa-4" outlined>
                <v-row no-gutters>
                  <v-col
                    cols="2"
                    class="d-flex justify-center align-self-start"
                  >
                    <v-avatar size="80" class="ml-10">
                      <v-img :src="consultation.photo"></v-img>
                    </v-avatar>
                  </v-col>
                  <v-col cols="10">
                    <div class="d-flex flex-wrap align-items-center mb-2">
                      <div>
                        <strong
                          >{{
                            $t('dashboard.consultationDialog.fullName')
                          }}
                          :</strong
                        >
                        {{ consultation.customerName }}
                      </div>
                    </div>
                    <div class="d-flex flex-wrap align-items-center mb-2">
                      <div>
                        <strong
                          >{{
                            $t('dashboard.consultationDialog.reason')
                          }}
                          :</strong
                        >
                        {{ consultation.appointmentReason }}
                      </div>
                    </div>
                    <div class="d-flex flex-wrap mb-2">
                      <div class="flex-grow-1 mr-4">
                        <strong
                          >{{
                            $t('dashboard.consultationDialog.duration')
                          }}
                          :</strong
                        >
                        {{ consultation.duration }}
                      </div>
                      <div class="flex-grow-1">
                        <strong
                          >{{
                            $t('dashboard.consultationDialog.price')
                          }}
                          :</strong
                        >
                        {{ consultation.price }}
                      </div>
                    </div>
                    <div class="d-flex flex-wrap mb-2">
                      <div class="flex-grow-1 mr-4">
                        <strong
                          >{{
                            $t('dashboard.consultationDialog.date')
                          }}
                          :</strong
                        >
                        {{ consultation.date }}
                      </div>
                      <div class="flex-grow-1">
                        <strong
                          >{{
                            $t('dashboard.consultationDialog.time')
                          }}
                          :</strong
                        >
                        {{ consultation.time }}
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      v-model="consultation.comment"
                      :label="$t('dashboard.consultationDialog.note')"
                      outlined
                      class="w-full mt-2 custom-textarea"
                      rows="2"
                      variant="filled"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <div class="mt-4 text-center">
                      <strong>{{
                        $t('dashboard.consultationDialog.confirm')
                      }}</strong>
                      <div class="d-flex justify-center mt-2">
                        <v-btn
                          color="secondary"
                          class="mr-2 text-white"
                          @click="
                            () =>
                              confirmPatientPresence(
                                consultation.id,
                                consultation.comment,
                              )
                          "
                        >
                          {{ $t('dashboard.consultationDialog.present') }}
                        </v-btn>
                        <v-btn
                          color="error"
                          @click="
                            () =>
                              confirmPatientAbsence(
                                consultation.id,
                                consultation.comment,
                              )
                          "
                        >
                          {{ $t('dashboard.consultationDialog.absent') }}
                        </v-btn>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-list-item>
          </v-list>
        </div>
      </v-card-text>
      <v-card-actions class="justify-end dialog-actions">
        <span class="dialog-text">
          {{ $t('dashboard.consultationDialog.modifyChoiceInfo') }}
        </span>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { nextTick, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import createHttpClient from '@/api/httpClient'
import MessageService from '../feedback/message/messageService'
import { useAskPatientPresenceToPractitionerStore } from '@/store/askPatientPresenceToPractitioner/askPatientPresenceToPractitionerStore'
import LoadingBackdropService from '../feedback/loadingBackdrop/loadingBackdropService'

export default {
  props: {
    dialog: Boolean,
  },
  emits: ['update:dialog'],
  setup(props, { emit }) {
    const { t } = useI18n()
    const httpClient = createHttpClient()

    // TODO avoir le Call API /
    const consultations = ref([
      // {
      //   id: 1,
      //   photo: avatar,
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 2,
      //   photo: avatar,
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 3,
      //   photo: avatar,
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 4,
      //   photo: avatar,
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 5,
      //   photo: avatar,
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 6,
      //   photo: avatar,
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 7,
      //   photo: avatar,
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 8,
      //   photo: avatar,
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 9,
      //   photo: avatar,
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 10,
      //   photo: avatar,
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
    ])

    const dialogAskPractitionerConsultationStore =
      useAskPatientPresenceToPractitionerStore()

    const updateDialog = value => {
      emit('update:dialog', value)
    }

    const toggleDialog = () => {
      updateDialog(false)
    }

    const validate = () => {
      updateDialog(false)
    }

    const confirmPatientPresence = async (id, comment) => {
      LoadingBackdropService.start()
      // Handle consultation confirmation
      try {
        await httpClient.post(
          `/appointment-notification/${id}/confirmed`,
          { comment },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
      } catch (error) {
        MessageService.error(t('common.error.errorHasOccurred'))
        console.error(error)
      }
      await dialogAskPractitionerConsultationStore.fetchConsultations()
      LoadingBackdropService.stop()
    }

    const confirmPatientAbsence = async (id, comment) => {
      LoadingBackdropService.start()
      try {
        // Handle consultation confirmation
        await httpClient.post(
          `/appointment-notification/${id}/unconfirmed`,
          { comment },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
      } catch (error) {
        MessageService.error(t('common.error.errorHasOccurred'))
        console.error(error)
      }
      await dialogAskPractitionerConsultationStore.fetchConsultations()
      LoadingBackdropService.stop()
    }

    const markAsNoShow = id => {
      // Handle marking patient as no-show
    }

    const fetchPastConsultations = async () => {
      // Fetch past consultations
      // const response = await httpClient.get('/past-consultations')
      // if (response.status !== 200) {
      //   throw Error('An error occurred while fetching past consultations.')
      // }
      // consultations.value = response.data
    }

    const onOpen = async () => {
      // await fetchPastConsultations()
      // console.log('something ')
    }

    watch(
      () => props.dialog,
      async newState => {
        if (newState) {
          await onOpen()
        }
      },
      {
        immediate: true,
      },
    )

    return {
      t,
      updateDialog,
      toggleDialog,
      confirmPatientPresence,
      confirmPatientAbsence,
      dialogAskPractitionerConsultationStore,
      onOpen,
    }
  },
}
</script>

<style scoped>
.v-btn {
  min-width: 100px;
}

.btn--save {
  min-width: 150px;
  background-color: #1a73e9;
}

.text-white {
  color: #fff !important;
}

.border-list {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
}

.v-card-text,
.v-list-item,
.v-card {
  background-color: #fff !important;
}

.v-list {
  background: none !important;
}

.dialog-header {
  background-color: #fef4eb;
  color: #f09954;
  text-align: center;
}

.h-overflow {
  max-height: 550px;
  overflow-y: auto;
}

.ml-10 {
  margin-left: -10px !important;
}

.align-self-start {
  align-self: flex-start;
}

.dialog-actions {
  background-color: white;
}

.dialog-text {
  font-size: 13px;
  color: gray;
  margin-left: 16px;
  margin-right: 16px;
}

.btn--save {
  background-color: #1a73e9;
  color: white;
}

.custom-textarea .v-input__control {
  background-color: #f5f5f5;
  border: none;
  border-radius: 8px;
}

.blur-background {
  backdrop-filter: blur(10px);
  box-shadow: 10px 10px 10px #00000072;
  &:deep(.v-overlay__scrim) {
    background-color: #fff;
  }
}

.border-radius-none {
  border-radius: 0 !important;
}
</style>
