/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */

// Plugins
import i18n from '@/plugins/i18n'
import vuetify from '@/plugins/vuetify'
import pinia from '@/store'
import router from '@/router'
import * as Sentry from '@sentry/vue'

// Types
import type { App } from 'vue'

export function registerPlugins(app: App) {
  Sentry.init({
    app,
    dsn: 'https://65f3773996db1bae42af66be77b6aec4@o252825.ingest.us.sentry.io/4506855235518464',
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
        networkDetailAllowUrls: [/\/(profile|subscription|payment)/],
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/api\.holitime\.org/],
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: import.meta.env.VITE_APP_ENV || 'development',
  })

  app.use(vuetify).use(router).use(pinia).use(i18n)
}
